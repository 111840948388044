import LoginPage from '../../src/login/LoginPage';

import nookies from 'nookies';

import { UserRoles } from '../../src/types/Users';

export async function getServerSideProps(context) {
    try {
        const cookies = nookies.get(context);

        const user = JSON.parse(cookies?.user || '{}');
        if (user?.role === UserRoles.Admin || user?.role === UserRoles.Client) {
            return {
                redirect: {
                    destination: '/dashboard',
                    permanent: false,
                },
            };
        }

        return { props: {} };
    } catch {
        return { props: { userData: {} } };
    }
}

export default LoginPage;
